import * as React from "react"
import { Row, Col } from 'react-bootstrap'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import styled from "styled-components"

const ShareIcon = () => (
  <svg fill="#016BFF" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
  viewBox="0 0 330 330"  style={{ height: '4vh', width: '4vh', marginLeft: '4vw' }}>
<g id="XMLID_24_">
 <path id="XMLID_25_" d="M154.389,265.602c0.351,0.35,0.719,0.683,1.103,0.997c0.169,0.138,0.347,0.258,0.52,0.388
   c0.218,0.164,0.432,0.333,0.659,0.484c0.212,0.142,0.432,0.265,0.649,0.395c0.202,0.121,0.4,0.248,0.608,0.359
   c0.224,0.12,0.453,0.221,0.681,0.328c0.215,0.102,0.427,0.21,0.648,0.301c0.223,0.092,0.45,0.167,0.676,0.247
   c0.235,0.085,0.468,0.175,0.709,0.248c0.226,0.068,0.456,0.119,0.685,0.176c0.246,0.062,0.489,0.131,0.739,0.181
   c0.263,0.052,0.528,0.083,0.794,0.121c0.219,0.031,0.435,0.073,0.658,0.095c0.492,0.048,0.986,0.075,1.48,0.075
   c0.494,0,0.988-0.026,1.48-0.075c0.225-0.022,0.444-0.064,0.667-0.096c0.262-0.037,0.524-0.068,0.784-0.12
   c0.255-0.05,0.503-0.121,0.754-0.184c0.223-0.057,0.448-0.105,0.669-0.172c0.246-0.075,0.483-0.167,0.724-0.253
   c0.221-0.08,0.444-0.152,0.662-0.242c0.225-0.093,0.44-0.202,0.659-0.306c0.225-0.106,0.452-0.206,0.672-0.324
   c0.21-0.112,0.408-0.239,0.611-0.361c0.217-0.13,0.437-0.252,0.648-0.394c0.222-0.148,0.431-0.314,0.643-0.473
   c0.179-0.134,0.362-0.258,0.536-0.4c0.365-0.3,0.714-0.617,1.049-0.949c0.016-0.016,0.033-0.028,0.049-0.044l70.002-69.998
   c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.857-21.213-0.001l-44.396,44.393V15c0-8.284-6.716-15-15-15
   c-8.284,0-15,6.716-15,15v203.785l-44.392-44.391c-5.858-5.858-15.356-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213
   L154.389,265.602z"/>
 <path id="XMLID_26_" d="M315,300H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h300c8.284,0,15-6.716,15-15S323.284,300,315,300z"/>
</g>
</svg>
)
const Top = ({ data }) => {
  const image = getImage(data.img)
  const whats = getImage(data.whatsappicon)
  const tel = getImage(data.telicon)


  return (
    <StyledSocialIconsMobile>
      <Row className="max-width row-top">
        <Col sm="6" md="6" lg="6" className="width-50 full-height position-relative">
          <div className="full-height">
            <GatsbyImage image={image}
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt={data.alt}
              objectFit="cover"
              objectPosition="50% 50%"
              className="full-height position-absolute img-person"
            />
          </div>

        </Col>
        <Col sm="6" md="6" lg="6" className="width-50  position-relative right">
          <div className="div-whats pe-15 position-absolute pb-3 right">
            <a className="no-text-decoration" href={"tel:" + data.number} target="_blank">
              <GatsbyImage image={tel}
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt={data.telalt}
                objectFit="cover"
                objectPosition="0% -110%"
                className="img-tel mb-2"
              />
            </a>

            <a className="no-text-decoration" href={"https://api.whatsapp.com/send?phone=" + data.number} target="_blank">
              <GatsbyImage image={whats}
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt={data.whatsappalt}
                objectFit="cover"
                objectPosition="0% -110%"
                className="img-whats mb-2"
              />
            </a>
            
              <a href="https://nuno-carvalho.invisual.pt/vcard.vcf" download="nuno_carvalho">
                <ShareIcon />
              </a>
              
            <br />
            <br />
            <br />
            <p className="vRegular title-large mb-1">{data.name}</p>
            <p className="vSemiBold mb-0 details-small">{data.company}</p>
            <p className="vRegular details-small mb-0">{data.job}</p>
          </div>
        </Col>
      </Row>
    </StyledSocialIconsMobile>
  )
}

export default Top

const StyledSocialIconsMobile = styled.div`
.max-width {
  width: 100%;
}
.row-top {
    height: 30vh;
}
.width-50 {
    width: 50%;
}
.full-height {
    min-height: 100%;
    height: 100%;
}
.img-person {
    width: 100%;
    height: 100%;
    transform: scale(1.4);
    @media (max-width: 990px) {
        transform: scale(1.4);
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin-top: 25%;
    }
    @media (min-width: 1536px){
        transform: scale(1.2);
    }
}
.div-whats {
    max-height: 25vh;
    width: 100%;
    bottom: 0px !important;
}
.right {
    text-align: right;
    float: right;
}
.no-text-decoration{
    text-decoration: none !important;
}
.img-whats {
    width: 20%;
}
@font-face {
  font-family: "vRegular";
  src: url("./Volte-W03-Regular.woff2") format("truetype");
}
@font-face {
  font-family: "vSemiBold";
  src: url("./Volte-Semibold.woff2") format("truetype");
}
/** END - import fonts **/

// USAGE
.vRegular {
  font-family: "vRegular", sans-serif;
  font-feature-settings: "lnum";
}
.vSemiBold {
  font-family: "vSemiBold", sans-serif;
  font-feature-settings: "lnum";
}
.details-small {
    font-size: calc(11px + 6 * ((100vw - 320px) / 680));
}
.title-large {
    font-size: 1.2rem;
}
`